import React from 'react';
import './About.css';
import GB from '../../../images/GB.png';
import USFlag from '../../../images/US.png';
import { CancelAboutModel, CurrentYear } from '../../../InnerFunctions';
import { AboutCancelIcon, AboutHeaderImg, ApacIcon, MailIcon, WebIcon } from '../../../Svg';

const About = (props: { isVisibleAbout: boolean; setIsVisibleAbout: Function }) => {
  return (
    <>
      <div
        className="about"
        onClick={(e) => CancelAboutModel(e, props)}
        data-testid="aboutModel"
        aria-label="About Osttra model"
      >
        <div className="about__model">
          <button className="about__cancel" aria-label="Close about Osttra model">
            {AboutCancelIcon}
          </button>
          <div className="about__header">
            {AboutHeaderImg}
            <p>
              We bring together four businesses that have been at the heart of post trade evolution and innovation for
              more than 25 years: MarkitServ, Traiana, TriOptima & Reset...
              <a href="https://osttra.com/company/about-us/" target="_about">
                read more
              </a>
            </p>
          </div>
          <div className="about__body">
            <div className="row">
              <div className="col-8">
                <p>Build Version: 1.0.0.2.1</p>
                <p>License: OSTTRA Subscription, Belongs to: OSTTRA Group</p>
                <p>
                  Warning: This computer program is protected by copyright law and international treaties. Unauthorized
                  reproduction or distribution of this program, or any portion of it, may result in server civil and
                  criminal penalties, and will be prosecuted to the maximum extent possible under the law.
                </p>
              </div>
              <div className="col-4">
                <h4>Customer Support</h4>
                <ul>
                  <li>
                    <div>
                      <img src={USFlag} alt="" />
                      <h6>U.S.</h6>
                    </div>
                    <a href="tel:+1 (866) 799 2724">+1 (866) 799 2724</a>
                  </li>
                  <li>
                    <div>
                      <img src={GB} alt="" />
                      <h6>U.K.</h6>
                    </div>
                    <a href="tel:+44 (808) 234 5941">+44 (808) 234 5941</a>
                  </li>
                  <li>
                    <div>
                      {ApacIcon}
                      <h6>APAC</h6>
                    </div>
                    <a href="tel:+65 315 84693">+65 315 84693</a>
                  </li>
                  <li>
                    <div>
                      {MailIcon}
                      <h6>Mail</h6>
                    </div>
                    <a href="mailto:support@osttra.com">support@osttra.com</a>
                  </li>
                  <li>
                    <div>
                      {WebIcon}
                      <h6>Web</h6>
                    </div>
                    <a href="https://osttra.com" target="_about">
                      https://osttra.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="about__footer">
            <p>
              You accept our{' '}
              <a href="https://osttra.com/osttra-terms-of-use/" target="_terms">
                Terms of Use
              </a>{' '}
              and have read our{' '}
              <a href="https://osttra.com/privacy-policy/" target="_privacy">
                Privacy Policy
              </a>{' '}
              by logging in. In order to provide the best user experience, you also acknowledge that OSTTRA uses{' '}
              <a href="https://osttra.com/cookies-policy/" target="_cookies">
                cookies
              </a>
              .
            </p>
            <p>
              © {CurrentYear} OSTTRA Group. Proprietary and Confidential.{' '}
              <a href="https://osttra.com/disclaimer/" target="_disclaimer">
                Disclaimer.
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
