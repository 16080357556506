import { useEffect } from 'react';

const MainContent = (props: { userMapAppName: string }) => {
  useEffect(() => {
    document.title = `${props.userMapAppName}`;
  }, [props.userMapAppName]);

  return (
    <div className="t-text-left">
      <h1 className="t-mb-l" aria-label="App Name">
        {props.userMapAppName}
      </h1>
    </div>
  );
};

export default MainContent;
