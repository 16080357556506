/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { Container, Loading } from '@trioptima/trids-react';
import Header from './components/Molecules/Header/Header';
import Footer from './components/Molecules/Footer/Footer';
import { useEffect, useRef, useState } from 'react';
import cardPropsType, { appUsersPropsType } from './propsType';
import MainContent from './components/Atoms/MainContent/MainContent';
import CardSession from './components/Organism/CardSession/CardSession';
import { redirectToSSOLogin } from './InnerFunctions';
import ErrorPage from "./ErrorPage";

function App() {
  const [userMapping, setUserMapping] = useState<cardPropsType>();
  const [searchData, setSearchData] = useState<appUsersPropsType[]>([]);
  const [errorMsgCondition, setErrorMsgCondition] = useState<string>('');
  const [errorMsgScreen, setErrorMsgScreen] = useState<string>('');
  const [emptyAppUser, setEmptyAppUser] = useState<boolean>(false);
  const [code, setCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [appName, setAppName] = useState<string>('');
  const formRef = useRef(null);
  let data: cardPropsType;
  const urlParamsSelect = new URL(window.location.href).searchParams;
  const getUrlAppName = urlParamsSelect.get('appName');
  const [responseCode,setResponseCode] =useState<string>("N/A");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/sso/api/v1/loginUserMapping?appName=${getUrlAppName}`, {
        credentials: 'include',
        headers: {
          'Access-Control-Request-Headers': 'Location',
        },
      });
      if (response?.status === 200) {
        setResponseCode(response?.status + '');
        data = await response.json();
        if (data && data.appUsers !== null) {
          if (data.appUsers.length === 1) {
            setAppName(data.appName);
            setErrorMsgCondition('RedirectUserIdApp');
            setEmptyAppUser(false);
              setTimeout(() => {
                // @ts-ignore
                formRef.current.submit();
              }, 1000);
          } else if (data.appUsers.length === 0) {
            setAppName(data.appName);
            setEmptyAppUser(true);
            setErrorMsgCondition("NoUserForApp")
          }
        } else {
          setEmptyAppUser(true);
          setErrorMsgCondition("NoUserForApp");
        }
      }

      if (response?.status === 400) {
        setResponseCode(response?.status + '');
        setErrorMsgCondition('IncorrectAppName');
      } else if (response?.status === 500) {
        setResponseCode(response?.status + '');
        setErrorMsgCondition('FetchUserMappingError');
      }else if (response?.status === 401) {
        setResponseCode(response?.status + '');
        redirectToSSOLogin();
      }

      if (data && data.appUsers !== null) {
        setSearchData(data.appUsers);
      }
      setUserMapping(data);
    };

    fetchData();
  }, []);

  const searchFunData = (appUserData: appUsersPropsType[]) => {
    setSearchData(appUserData);
  };

  useEffect(() => {
    switch (errorMsgCondition) {
      case 'RedirectAzureLogin':
        setErrorMsgScreen('Seems you are logged out, please wait!');
        setLoading(true);
        break;
      case 'IncorrectAppName':
        setErrorMsgScreen('You may not have access to application or application is not setup for SSO');
        setLoading(false);
        break;
      case 'RedirectUserIdApp':
        setErrorMsgScreen(`You are being redirected to ${appName}`);
        setLoading(true);
        break;
      case 'FetchUserMappingError':
        setErrorMsgScreen(
            `Oops seems we are getting some issue while fetching all the users, try refreshing the page`
        );
        setLoading(false);
        break;
      case "NoUserForApp":
        setErrorMsgScreen(`Seems you don't have access to ${appName} app or user mapping is missing, contact your admin`);
        setLoading(false);
        break;
      default:
        setCode(true);
    }
  }, [errorMsgCondition]);

  return (
      <div className={`App ${(!errorMsgCondition && code && userMapping) ? "App":"Error-left"}`}>
      {userMapping && userMapping.appUsers && !emptyAppUser ? (
        <form action={userMapping?.appUrl} method={'POST'} name={'myForm'} id={'myForm'} ref={formRef}>
          <input type={'text'} hidden={true} name="user" value={userMapping.appUsers[0].userId} />
          <input type={'text'} hidden={true} name="accessToken" value={userMapping?.accessToken} />
        </form>
      ) : null}
      {!errorMsgCondition && code && userMapping ? (
        <Container>
          <Header userName={userMapping.userName} userParentOrg={userMapping.userParentOrg} />
          <div className="App__Body t-pt-e">
            <div className="App__BodyCenter">
              <MainContent userMapAppName={userMapping.appName} />
              {emptyAppUser ? (
                <div className="LoadingText">
                  <h2>{errorMsgScreen}</h2>
                </div>
              ) : (
                <CardSession
                  userMapping={userMapping}
                  searchData={searchData}
                  appUsers={userMapping.appUsers}
                  searchFunData={searchFunData}
                />
              )}
            </div>
          </div>
          <Footer />
        </Container>
      ) : (
          <Container>
            <Header
                userName={userMapping ? userMapping.userName : ''}
                userParentOrg={userMapping ? userMapping.userParentOrg : ''}
            />
            <div>
              {errorMsgCondition === 'RedirectUserIdApp' ||
              errorMsgCondition === 'RedirectAzureLogin' ||
              errorMsgCondition === ''
                   ? (
                  <div className='LoadingText'>
                    <h2>{errorMsgScreen}</h2>
                    {loading && <Loading size="big" />}
                  </div>
              ) : (
                  <div className="Error-page">
                    <ErrorPage errorMsgScreen={errorMsgScreen} responseCode={responseCode}></ErrorPage>
                  </div>
              )}
            </div>
            <Footer />
          </Container>
      )}
    </div>
  );
}

export default App;
