import { CurrentYear } from '../../../InnerFunctions';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer t-py-l t-mt-e t-text-gray-mid t-px-l t-flex" aria-label="Bottom of the page">
        <div>
          © {CurrentYear} OSTTRA Group. Proprietary and Confidential.{' '}
          <a href="https://osttra.com/disclaimer/" target="_disclaimer">
            Disclaimer.
          </a>
        </div>
        <ul className="footer__links t-flex t-m-0">
          <li className="t-px-s">
            <a href="https://osttra.com/osttra-terms-of-use/" target="_terms" className="t-text-blue">
              Terms of Use
            </a>
          </li>
          <li className="t-px-s">
            <a href="https://osttra.com/privacy-policy/" target="_policy" className="t-text-blue">
              Privacy Policy
            </a>
          </li>
          <li className="t-px-s">
            <a href="https://osttra.com/cookies-policy/" target="_cookies" className="t-text-blue">
              Cookies
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
