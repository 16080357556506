/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, TopBar } from '@trioptima/trids-react';
import logo from '../../../images/logo.png';
import './Header.css';
import { useEffect, useState } from 'react';
import { HelpDropdown, getEnvType, showAboutModel } from '../../../InnerFunctions';
import About from '../About/About';

const Header = (props: { userName: string; userParentOrg: string }) => {
  const [envVal, setEnvVal] = useState<string>('');
  const [isVisibleAbout, setIsVisibleAbout] = useState<boolean>(false);
  const [isHelpClicked, setIsHelpClicked] = useState<boolean>(false);
  const [showClassAdd, setShowClassAdd] = useState<string>('');
  const userIDSplit = props.userName.split('@');
  const userNameSelect = userIDSplit[0].split('.');
  const firstName = userNameSelect[0];
  const secName = userNameSelect[1] ? userNameSelect[1] : "";
  const firstNameFistChar = firstName?.charAt(0);
  const lastNameFistChar = secName?.charAt(0);
  const current_url = window.location.href;
  const url_object = new URL(current_url);
  const domain = url_object.hostname;
  const splitDomain = domain.split('.');

  useEffect(() => {
    if (splitDomain?.length === 3) {
      setEnvVal('');
    } else if (splitDomain?.length === 4) {
      const splitEnv = splitDomain[0].split('-')
      setEnvVal(splitEnv[0])
    }
  }, [splitDomain]);

  document.addEventListener('click', clickOutside);
  function clickOutside(e: any) {
    if (e.target.className !== 't-help') {
      setIsHelpClicked(false);
      setShowClassAdd('');
    }
  }

  return (
    <>
      {isVisibleAbout ? <About isVisibleAbout={isVisibleAbout} setIsVisibleAbout={setIsVisibleAbout} /> : null}
      <div className="header">
        <TopBar
          logo={<TopBar.Logo href="https://osttra.com/" image={logo} productName="Osttra Platform" />}
          environment={getEnvType(envVal)}
          menuRight={
            <>
              <div className="tooltip">
                <TopBar.DropDown
                  id={'account-settings'}
                  title={props.userName}
                  avatar={
                    firstNameFistChar && lastNameFistChar ? (
                      <Avatar initials={`${firstNameFistChar}${lastNameFistChar}`} />
                    ) : null
                  }
                  subTitle={props.userParentOrg}
                ></TopBar.DropDown>
                <span className="tooltiptext">
                  {props.userName}
                  <br />
                  {props.userParentOrg}
                </span>
              </div>
              <div className={`header__helpBlock ${showClassAdd}`} aria-label="Help section">
                <a
                  className="t-help"
                  onClick={() => HelpDropdown({ isHelpClicked, setIsHelpClicked, setShowClassAdd })}
                >
                  Help
                </a>
                {isHelpClicked ? (
                  <ul className="helpBlock__helpList" aria-label="Help section dropdown">
                    <li>
                      <a
                        className="header__about"
                        onClick={() => showAboutModel({ isVisibleAbout, setIsVisibleAbout })}
                      >
                        About OSTTRA
                      </a>
                    </li>
                  </ul>
                ) : null}
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export default Header;
