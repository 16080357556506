import { useState } from 'react';
import './ShowMore.css';

const ShowMore = (props: { userMapLength: number }) => {
  const [showBlock, setShowBlock] = useState(false);
  const showMore = (e: any) => {
    const showMoreHeading = e.target.parentElement.childNodes[0]?.classList;
    if (showMoreHeading.length <= 1) {
      showMoreHeading.value = 'card showMoreActive';
      setShowBlock(true);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (props.userMapLength < 11) {
    return null;
  }

  return (
        <>
          {!showBlock ? (
            <button
              className="t-text-cta t-block t-text-center t-mt-l t-py-r showMoreClass"
              aria-label="show more cards button"
              onClick={showMore}
            >
              Load more
            </button>
          ) : (
            <button
              className="backToTop t-mt-l t-mb-b"
              aria-label="Back to top of page button"
              onClick={handleScrollToTop}
            >
              <i className="t-icon t-icon-arrow-up" aria-label="upper arrow"></i>Back to Top
            </button>
          )}
        </>
  );
};

export default ShowMore;
