/* eslint-disable react-hooks/rules-of-hooks */
import { appUsersPropsType } from '../../../propsType';
import './Search.css';

const Search = (props: { appUsers: Array<appUsersPropsType> | null; searchFunData: Function }) => {
  const UserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchResult;
    if (Array.isArray(props.appUsers)) {
      searchResult = props.appUsers.filter(
        (userIdSelect) =>
          userIdSelect.userId.toUpperCase().includes(e.target.value.toUpperCase()) ||
          userIdSelect.organization.toUpperCase().includes(e.target.value.toUpperCase())
      );

      if (searchResult.length >= 1 && e.target.value) {
        props.searchFunData(searchResult);
      } else {
        if (e.target.value.length === 0 && searchResult.length !== 0) {
          props.searchFunData(props.appUsers);
        } else {
          props.searchFunData({});
        }
      }
    }
  };

  return (
    <>
      <div className="t-input">
        <i className="t-icon t-icon-search"></i>
        <input
          type="search"
          placeholder="Search"
          data-testid="searchInput"
          onChange={UserSearch}
          aria-label="Search Box"
        />
      </div>
    </>
  );
};

export default Search;
